<template>
  <div id="editable-input">
    <div v-if="!isEdit" class="notEdit">
      <span>{{ value }} </span>
      <i
        class="iconfont icon-bianji"
        v-if="!isRead"
        title="编辑"
        @click="edit()"
      ></i>
    </div>
    <el-input v-model="newVal" v-if="isEdit" :autofocus="true" v-bind="config">
      <i v-show="isLoading" slot="suffix" class="el-icon-loading"></i>
      <i
        slot="suffix"
        class="iconfont icon-zhengque"
        title="保存"
        @click="save()"
        v-show="!isLoading"
      ></i>
      <i
        slot="suffix"
        class="iconfont icon-cuowu"
        title="取消"
        @click="cancel()"
        v-show="!isLoading"
      ></i>
    </el-input>
  </div>
</template>
<script>
export default {
  props: {
    //是否为只读模式
    isRead: {
      type: Boolean,
      default: true,
    },
    params: {
      type: Object,
      default: () => {},
    },
    //修改的接口
    url: {
      type: String,
      default: "",
    },
    //修改的属性
    attr: {
      type: String,
      default: "",
    },
    //修改的原始值
    value: {
      type: [String, Number],
      default: "",
    },
    //修改请求的类型
    method: {
      type: [String],
      default: "post",
    },
    //自定义编辑事件
    oncustom: {
      type: Function,
      default: null,
    },
    //配置参数
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      //是否编辑状态
      isEdit: false,
      //修改后的新值
      newVal: "",
      //保存之后的loading图标
      isLoading: false,
    };
  },
  methods: {
    edit() {
      if (this.oncustom) {
        this.oncustom();
      } else {
        this.newVal = this.value;
        this.isEdit = true;
      }
    },
    async save() {
      try {
        this.isLoading = true;
        let res = await this.$http[this.method](this.url, {
          [this.attr]: this.newVal===""?null:this.newVal,
          ...this.params,
        });
        if (res.code === 0) {
          this.$message.success("编辑成功!");
          this.isEdit = false;
          //触发父组件传来的确认回调
          this.$emit("save");
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error("编辑失败!");
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
    cancel() {
      //触发父组件传来的取消回调
      this.$emit("cancel");
      this.newVal = this.value;
      this.isEdit = false;
    },
  },
};
</script>
<style lang="scss">
#editable-input {
  $color: rgba(0, 206, 192, 1);
  $color-hover: rgba(0, 206, 192, 0.6);
  width: 250px;
  height: 32px;
  line-height: 32px;
  box-sizing: border-box;
  display: inline-block;
  .notEdit {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px 0 16px;
    span {
      line-height: initial;
      word-break: break-all;
      margin-right: 10px;
    }
  }
  .el-input {
    .el-input__inner {
      padding-right: 50px;
      border-radius: 0;
      font-size: 12px;
    }
    .el-input__suffix {
      line-height: 32px;
    }
  }
  .iconfont,
  .el-icon-loading {
    cursor: pointer;
    color: $color;
    transition: all 0.3s;
    font-size: 18px;
    margin-right: 3px;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: $color-hover;
    }
  }
  .el-icon-loading {
    font-size: 14px;
    cursor: initial;
  }
}
</style>
